import { Form, InputNumber, Input, Select } from "antd";

export default function FormInput(props) {
  return (
    <div {...props} className="grid grid-cols-12 gap-3">
      <div className="col-span-12 lg:col-span-3">
        <Form.Item
          name={["data", "tag_articles", "label"]}
          className="w-full"
          label="Заголовок блока (отображается слева)"
        >
          <Input placeholder="Введите заголовок" className="w-full" />
        </Form.Item>
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Form.Item
          name={["data", "tag_articles", "extra_label"]}
          className="w-full"
          label="Подзаголовок блока"
        >
          <Input
            placeholder="Введите подзаголовок (отображается справа)"
            className="w-full"
          />
        </Form.Item>
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Form.Item
          name={["data", "tag_articles", "tag_id"]}
          className="w-full"
          label="ID тега"
          tooltip="ID тега, по которому будут отображаться материалы. ID можно узнать при редактировании Тега в колонке «ID»"
        >
          <InputNumber
            placeholder="Введите ID тега"
            className="w-full"
            controls={false}
            min={1}
          />
        </Form.Item>
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Form.Item
          name={["data", "tag_articles", "random"]}
          className="w-full"
          label="Случайные материалы"
          tooltip="Показывать случайные материалы из тега при каждой загрузке страницы"
        >
          <Select
            className="w-full"
            placeholder="Выберите"
            options={[
              { value: true, label: "Да" },
              { value: false, label: "Нет" },
            ]}
          />
        </Form.Item>
      </div>
    </div>
  );
}
