import * as Pages from "@pages/admin";
import Layout from "@modules/layouts/editor";

const routes = [
  {
    path: "/authors/edit/:id",
    element: (
      <Layout page="authors">
        <Pages.Authors.Edit />
      </Layout>
    ),
  },
  {
    path: "/authors/new",
    element: (
      <Layout page="authors">
        <Pages.Authors.New />
      </Layout>
    ),
  },
  {
    path: "/authors",
    element: (
      <Layout page="authors">
        <Pages.Authors.List />
      </Layout>
    ),
  },
  {
    path: "/people/edit/:id",
    element: (
      <Layout page="people">
        <Pages.People.Edit />
      </Layout>
    ),
  },
  {
    path: "/people",
    element: (
      <Layout page="people">
        <Pages.People.List />
      </Layout>
    ),
  },
  {
    path: "/meta/edit/:id",
    element: (
      <Layout page="meta">
        <Pages.Meta.Edit />
      </Layout>
    ),
  },
  {
    path: "/meta",
    element: (
      <Layout page="meta">
        <Pages.Meta.List />
      </Layout>
    ),
  },
  {
    path: "/settings/pages/edit/:type",
    element: (
      <Layout page="settings-pages">
        <Pages.Settings.Pages.Edit />
      </Layout>
    ),
  },
  // {
  //   path: "/settings/pages",
  //   element: (
  //     <Layout page="settings-pages">
  //       <Pages.Settings.Pages.List />
  //     </Layout>
  //   ),
  // },
  // {
  //   path: "/settings/editorial",
  //   element: (
  //     <Layout page="editorial">
  //       <Pages.Settings.Editorial />
  //     </Layout>
  //   ),
  // },
  // {
  //   path: "/settings/agreement",
  //   element: (
  //     <Layout page="agreement">
  //       <Pages.Settings.Agreement />
  //     </Layout>
  //   ),
  // },
  // {
  //   path: "/settings/contacts",
  //   element: (
  //     <Layout page="contacts">
  //       <Pages.Settings.Contacts />
  //     </Layout>
  //   ),
  // },
  {
    path: "/settings",
    element: (
      <Layout page="global">
        <Pages.Settings.Global />
      </Layout>
    ),
  },
  {
    path: "/users/edit/:id",
    element: (
      <Layout page="users">
        <Pages.Users.Edit />
      </Layout>
    ),
  },
  {
    path: "/users",
    element: (
      <Layout page="users">
        <Pages.Users.List />
      </Layout>
    ),
  },
  {
    path: "/tags/edit/:id",
    element: (
      <Layout page="tags">
        <Pages.Tags.Edit />
      </Layout>
    ),
  },
  {
    path: "/tags/new",
    element: (
      <Layout page="tags">
        <Pages.Tags.New />
      </Layout>
    ),
  },
  {
    path: "/tags",
    element: (
      <Layout page="tags">
        <Pages.Tags.List />
      </Layout>
    ),
  },
  {
    path: "/categories/edit/:id",
    element: (
      <Layout page="categories">
        <Pages.Categories.Edit />
      </Layout>
    ),
  },
  {
    path: "/categories/new",
    element: (
      <Layout page="categories">
        <Pages.Categories.New />
      </Layout>
    ),
  },
  {
    path: "/categories",
    element: (
      <Layout page="categories">
        <Pages.Categories.List />
      </Layout>
    ),
  },
  {
    path: "/projects/edit/:id",
    element: (
      <Layout page="projects">
        <Pages.Projects.Edit />
      </Layout>
    ),
  },
  {
    path: "/projects/new",
    element: (
      <Layout page="projects">
        <Pages.Projects.New />
      </Layout>
    ),
  },
  {
    path: "/projects",
    element: (
      <Layout page="projects">
        <Pages.Projects.List />
      </Layout>
    ),
  },
  {
    path: "/articles/edit/:id",
    element: (
      <Layout page="articles">
        <Pages.Articles.Edit />
      </Layout>
    ),
  },
  {
    path: "/articles/new",
    element: (
      <Layout page="articles">
        <Pages.Articles.New />
      </Layout>
    ),
  },
  {
    path: "/articles",
    element: (
      <Layout page="articles">
        <Pages.Articles.List />
      </Layout>
    ),
  },
  {
    path: "/",
    element: (
      <Layout page="dashboard">
        <Pages.Dashboard />
      </Layout>
    ),
  },
];

export default routes;
