import {
  Modal,
  Form,
  Input,
  message,
  Select,
  Divider,
  Alert,
  Button,
} from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import { useState } from "react";
import * as API from "@api";

export default function New({ open = false, onClose }) {
  const [loading, setLoading] = useState(false);

  const onSave = async (values) => {
    setLoading(true);

    API.Users.create(values)
      .then((res) => {
        if (!res || res?.error || !res?.data?.id) {
          throw new Error(
            "Ошибка добавления нового пользователя. Пожалуйста, убедитесь, что почта не занята и попробуйте снова.",
            2
          );
        }

        message.success("Пользователь успешно добавлен", 2, () =>
          window.location.reload()
        );
      })
      .catch((err) => {
        return message.error(err?.message || "Ошибка", 2);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Modal
      okText="Добавить"
      title="Новый пользователь"
      open={open}
      onCancel={onClose}
      destroyOnClose
      maskClosable={false}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      centered
      closable={!loading}
    >
      <Form
        disabled={loading}
        onFinish={onSave}
        onFinishFailed={(err) =>
          err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
        }
        layout="vertical"
        className="w-full form-container"
      >
        <Form.Item
          className="w-full mb-2"
          label="Имя"
          name="name"
          rules={[
            {
              required: true,
              message: "Это поле обязательное",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} placeholder="Введите имя" />
        </Form.Item>
        <Form.Item
          className="w-full mb-2"
          label="Эл.почта"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Введите корректный адрес эл.почты",
            },
          ]}
        >
          <Input
            autoComplete="off"
            prefix={<MailOutlined />}
            placeholder="Введите почту"
          />
        </Form.Item>
        <Form.Item
          className="w-full mb-2"
          label="Роль"
          name="role"
          rules={[
            {
              required: true,
              message: "Это поле обязательное",
            },
          ]}
        >
          <Select
            placeholder="Выберите роль"
            options={[
              {
                label: "Администратор",
                value: "admin",
              },
              {
                label: "Редактор",
                value: "editor",
              },
            ]}
          />
        </Form.Item>
        <Divider />
        <Alert
          type="warning"
          description="На почту данного пользователя будет направлено приглашение. Пользователь сам задаст пароль входа."
          showIcon
          className="p-3"
        />
        <Divider />
        <div className="flex flex-wrap justify-end items-center gap-2">
          <Button disabled={loading} onClick={onClose} className="m-0">
            Отменить
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            htmlType="submit"
            className="m-0"
            type="primary"
          >
            Добавить пользователя
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
