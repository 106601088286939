import { Link, Button, Modal, Input, message } from "antd";
import { PlusOutlined, LinkOutlined } from "@ant-design/icons";
import { Settings, OfflineWidget } from "@modules";
import { Breadcrumb } from "@components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as API from "@api/settings";

export default function SettingsPage() {
  const [loading, setLoading] = useState(false);
  const [newPageModalVisible, setNewPageModalVisible] = useState(false);
  const [newPageData, setNewPageData] = useState({ slug: null, title: null });
  const navigate = useNavigate();

  return (
    <div className="container max-content">
      <Breadcrumb
        data={[{ title: "Страницы сайта", href: "/settings/pages" }]}
      />
      <div className="flex justify-between items-center flex-wrap gap-4 mb-7">
        <h1 className="text-3xl m-0">Страницы сайта</h1>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setNewPageModalVisible(true)}
        >
          Добавить страницу
        </Button>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Settings.Pages.List />
        </div>
      </div>
      <OfflineWidget />
      <Modal
        title="Добавить страницу"
        open={newPageModalVisible}
        onCancel={() => setNewPageModalVisible(false)}
        centered
        loading={loading}
        okText="Добавить"
        onOk={() => {
          if (!newPageData?.slug || !newPageData?.title) {
            return message.error("Заполните все поля", 2);
          }

          setLoading(true);

          return API.create({
            type: `page:${newPageData?.slug?.toLowerCase()}`,
            data: {
              title: newPageData?.title,
            },
          })
            .then((res) => {
              setLoading(false);

              if (res?.error) {
                return message.error(
                  res?.message || "Ошибка, попробуйте позже",
                  2
                );
              }

              message.success("Страница успешно добавлена", 2, () =>
                navigate("/settings/pages/edit/page:" + newPageData?.slug)
              );
            })
            .catch((err) => {
              setLoading(false);
              message.error(err?.message || "Ошибка, попробуйте позже", 2);
            });
        }}
        cancelText="Отмена"
      >
        <div className="flex flex-col gap-2">
          <div>
            <span className="text-sm font-normal text-black mb-2 block">
              Путь (slug) страницы
            </span>
            <Input
              addonBefore={<LinkOutlined />}
              className="w-full"
              placeholder="Введите путь страницы (напр. about)"
              disabled={loading}
              value={newPageData?.slug}
              onChange={(e) =>
                setNewPageData({ ...newPageData, slug: e.target.value })
              }
              // slugify the input
              onBlur={(e) =>
                setNewPageData({
                  ...newPageData,
                  slug: e.target.value
                    .toLowerCase()
                    .replace(/[^a-z0-9]/g, "-")
                    .replace(/-+/g, "-")
                    .replace(/^-|-$/g, ""),
                })
              }
            />
            <span className="text-sm font-normal text-black mb-2 mt-3 block">
              Название страницы
            </span>
            <Input
              className="w-full"
              disabled={loading}
              placeholder="Введите название страницы (Напр. О нас)"
              value={newPageData?.title}
              onChange={(e) =>
                setNewPageData({ ...newPageData, title: e.target.value })
              }
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
