import { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as API from "@api/auth";

export default function LoginForm({ token, email }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onConfirm = async (data) => {
    data.token = await executeRecaptcha();
    data.userToken = token;
    data.email = email;

    if (!data.token || !data.userToken || !data.email) {
      return;
    }

    setLoading(true);

    return API.recover(data)
      .then((res) => {
        setLoading(false);

        if (res.error) {
          return message.error(
            "Ошибка подтверждения почты, пользователь не найден или ссылка устарела"
          );
        }

        navigate("/");
        return message.success(
          "Регистрация успешна, пожалуйста войдите в систему"
        );
      })
      .catch((err) => {
        setLoading(false);
        return message.error("Ошибка регистрации, попробуйте позже");
      });
  };

  return (
    <Form
      tabIndex={1}
      className="login-form !mb-0"
      name="signin"
      size="large"
      initialValues={{ remember: true }}
      onFinish={onConfirm}
      disabled={loading}
    >
      <h1 className="text-3xl font-semibold text-black leading-tight mb-2">
        Подтверждение регистрации
      </h1>
      <span className="block text-lg font-normal mb-5 text-gray-700">
        Добро пожаловать в систему, пожалуйста, введите новый пароль
      </span>
      <Form.Item
        name="password"
        className="my-4"
        rules={[
          {
            required: true,
            message: "Пожалуйста, введите пароль",
            min: 6,
          },
        ]}
      >
        <Input.Password
          autoCapitalize="false"
          disabled={loading}
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Введите новый пароль"
        />
      </Form.Item>
      <Form.Item
        name="confirm_password"
        className="my-4"
        rules={[
          {
            required: true,
            message: "Пожалуйста, подтвердите пароль",
            min: 6,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("Пароли не совпадают, пожалуйста, проверьте")
              );
            },
          }),
        ]}
      >
        <Input.Password
          autoCapitalize="false"
          disabled={loading}
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Подтвердите пароль"
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="admin-login-form-button"
          loading={loading}
          block
        >
          Зарегистрироваться
        </Button>
      </Form.Item>
    </Form>
  );
}
