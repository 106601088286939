import { Navigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@contexts";
import { Recover } from "@modules";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { message } from "antd";
import style from "./style.module.scss";
import config from "@config";

export default function RecoverPage(props) {
  const { currentUser } = useAuth();
  const [params] = useSearchParams();
  const token = params.get("token") || null;
  const email = params.get("email") || null;

  // If user is authenticated
  if (currentUser) {
    return <Navigate to="/" />;
  }

  if (!token || !email) {
    message.error("Неверная ссылка для восстановления доступа", 2);
    return <Navigate to="/login" />;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
      <div className={style.container}>
        <div className={style.left}>
          <img src="/img/logo-light.svg" alt="Mustafin Magazine" />
        </div>
        <div className={style.right}>
          <div className="hidden md:block" />
          <div className="max-w-full md:max-w-sm my-10">
            <Recover className="w-full" token={token} email={email} />
          </div>
          <div>
            <span className="text-sm text-gray-700">
              &copy; {new Date().getFullYear()} Mustafin Magazine — Разработано
              в Musan Group.
            </span>
          </div>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
}
